// Fonts
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,600); // Variables
@import "variables"; // Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap"; //@import "node_modules/dropzone/src/dropzone";

.navbar-brand img {
    height: 30px;
    top: -4px;
    position: relative;
}

.dropzone-container {
    border-radius: 2px;
    background: white;
    height: 150px;
    background: white;
    //padding: 54px;
    border: 2px dashed $brand-primary;
    cursor: pointer;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dz-drag-over {
    border: 2px solid rgba(0, 0, 0, 0.3) !important;
}

.spacer__15 {
    height: 15px;
}

.prog {
    //display: none;
    .alert {
        //display: none;
    }
}

html {
    position: relative;
    min-height: 100%;
}

body {
    /* Margin bottom by footer height */
    margin-bottom: 60px;
}

.footer {


    position: absolute;
    bottom: 0;
    width: 100%;
    /* Set the fixed height of the footer here */
    height: 60px;
    background-color: #252525;
    .x {
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    img {
        display: block;
        max-height: 40px;
    }
    p {
        padding: 0;
        margin: 0;
    }
}
.container.flex {
    color: #fff;
    display:flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 140px);
    width: 100%;
    h3 {
        margin: 0;
        margin-bottom: 10px;
    }
}
.start {
    background: url("/images/810.jpg") no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
.inner-dotted {
    height: 100%;
    //height: calc(100vh - 60px);
}
.dotted {
    //padding: 2.25em 1.6875em;
    background-image: -webkit-repeating-radial-gradient(center center, rgba(0,0,0,.2), rgba(0,0,0,.2) 1px, transparent 1px, transparent 100%);
    background-image: -moz-repeating-radial-gradient(center center, rgba(0,0,0,.2), rgba(0,0,0,.2) 1px, transparent 1px, transparent 100%);
    background-image: -ms-repeating-radial-gradient(center center, rgba(0,0,0,.2), rgba(0,0,0,.2) 1px, transparent 1px, transparent 100%);
    background-image: repeating-radial-gradient(center center, rgba(0,0,0,.2), rgba(0,0,0,.2) 1px, transparent 1px, transparent 100%);
    -webkit-background-size: 3px 3px;
    -moz-background-size: 3px 3px;
    background-size: 3px 3px;
}
